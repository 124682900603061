import {
  AutomationItemStatus,
  AutomationItemType,
  AutomationRequirementType,
  DateQuestionInitialState,
  EligibleForTrialStatus,
  WeekDay,
} from '@fdha/graphql-api-admin';

export const automationItemLabelByValue: Record<AutomationItemType, string> = {
  [AutomationItemType.Sachets]: 'Sachet request',
  [AutomationItemType.FrozenFood]: 'Quick Ship request',
};

export const automationItemStatusLabelByValue: Record<
  AutomationItemStatus,
  string
> = {
  [AutomationItemStatus.Checking]: 'Checking...',
  [AutomationItemStatus.Incomplete]: 'Incomplete ',
  [AutomationItemStatus.Invalid]: 'Invalid',
  [AutomationItemStatus.Scheduled]: 'Scheduled',
  [AutomationItemStatus.Triggered]: 'Triggered',
  [AutomationItemStatus.Error]: 'Error',
};

export const automationRequirementLabelByValue: Record<
  AutomationRequirementType,
  string
> = {
  [AutomationRequirementType.DaysOnDiet]: 'Days on diet',
  [AutomationRequirementType.DietPlan]: 'Diet plan',
  [AutomationRequirementType.EligibilityInTrial]: 'Eligibility',
  [AutomationRequirementType.MedicalFoodStartDate]: 'Medical food start date',
  [AutomationRequirementType.Sachets]: 'Sachets',
  [AutomationRequirementType.SnacksAndSides]: 'Snacks and sides per day',
};

export const dateQuestionInitialStateLabelByValue: Record<
  DateQuestionInitialState,
  string
> = {
  [DateQuestionInitialState.PreloadedWithCurrentDate]: 'User current date',
  [DateQuestionInitialState.NoPreloadedDate]: 'No preloaded date',
};

export const trialEligibilityLabelByValue: Record<
  EligibleForTrialStatus,
  string
> = {
  [EligibleForTrialStatus.Eligible]: 'Eligible',
  [EligibleForTrialStatus.Ineligible]: 'Ineligible',
  [EligibleForTrialStatus.Unconfirmed]: 'Unconfirmed',
};

export const weekdayLabelByValue: Record<WeekDay, string> = {
  [WeekDay.Mon]: 'Monday',
  [WeekDay.Tue]: 'Tuesday',
  [WeekDay.Wed]: 'Wednesday',
  [WeekDay.Thu]: 'Thursday',
  [WeekDay.Fri]: 'Friday',
  [WeekDay.Sat]: 'Saturday',
  [WeekDay.Sun]: 'Sunday',
};
