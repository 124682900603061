import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { signOut } from '@aws-amplify/auth';
import {
  Accordion,
  CardButton,
  convertMaskToE164,
  useSnackbar,
} from '@fdha/web-ui-library';
import { displayProtectedImage, ProfileChangePassword } from '@fdha/web-auth';
import {
  GetProfileDocument,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUploadProfilePictureMutation,
} from '@fdha/graphql-api-admin';

import { useAddRemoveGroups, useGetUserType } from '../../hooks';
import DescriptionCard from '../../components/DescriptionCard/DescriptionCard';
import CoachCard, { CoachData } from '../../components/CoachCard/CoachCard';
import BasePage from '../../components/BasePage/BasePage';
import CoachAvailability from '../../components/CoachAvailability/CoachAvailability';
import SelectMultipleGroups from '../../components/SelectMultipleGroups/SelectMultipleGroups';

interface StateProps {
  backRoute: string;
}

const Profile = () => {
  const location = useLocation();
  const state = location.state as StateProps;
  const navigate = useNavigate();
  const { isAdmin } = useGetUserType();
  const snackbar = useSnackbar();

  const backRouteRef = useRef(state?.backRoute ?? '/');

  const { data } = useGetProfileQuery();
  const [uploadPicture] = useUploadProfilePictureMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [availability, setAvailability] = useState(
    data?.me.new_coachings_available
  );
  const { addRemoveGroups, removeGroups } = useAddRemoveGroups();

  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  const [userData, setUserData] = useState<CoachData>({
    name: data?.me?.name,
    email: data?.me?.email,
    created_at: data?.me?.created_at,
    phone_number: data?.me?.phone_number,
    picture: data?.me?.picture,
  });

  useEffect(() => {
    setSelectedGroups(data?.me?.groups || []);

    setUserData({
      name: data?.me?.name,
      email: data?.me?.email,
      created_at: data?.me?.created_at,
      phone_number: data?.me?.phone_number,
      picture: data?.me?.picture,
    });
  }, [data]);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('login', { replace: true });
    } catch (error) {
      console.error('error signing out: ', error);
    }
  };

  const handleUpload = async (imageFile: File) => {
    await uploadPicture({
      variables: {
        picture: imageFile,
      },
      refetchQueries: [GetProfileDocument],
    });
  };

  const changeCoachUser = async (
    prop: string,
    newValue: boolean,
    errorMessage: string
  ) => {
    try {
      setAvailability(newValue);
      await updateProfile({
        variables: {
          props: {
            [prop]: newValue,
            name: data?.me.name,
            email: data?.me.email,
          },
        },
        refetchQueries: [GetProfileDocument],
      });
    } catch (e) {
      setAvailability(!newValue);
      snackbar.showSnackbar({
        message: `Failed to update user ${errorMessage}`,
        severity: 'error',
      });
    }
  };

  const handleEditProfile = async (profile: {
    name?: string;
    email?: string;
    phone_number?: string;
  }) => {
    setUserData({
      ...userData,
      name: profile.name,
      email: profile.email,
      phone_number: convertMaskToE164(profile.phone_number),
    });
    await updateProfile({
      variables: {
        props: {
          email: profile.email,
          phone_number: convertMaskToE164(profile.phone_number),
          name: profile.name,
          description: data?.me.description,
        },
      },
      refetchQueries: [GetProfileDocument],
    });
  };

  return (
    <BasePage data-testid="MY_PROFILE_CONTAINER">
      <BasePage.BackButton to={backRouteRef.current} />
      <Typography variant="h5" sx={{ marginBottom: 3 }}>
        Your profile
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        flex="1 0 auto"
        flexWrap="nowrap"
      >
        <Stack flex={1} spacing={2}>
          <CoachCard
            handleUpload={handleUpload}
            downloadPicture={displayProtectedImage}
            user={userData}
            handleUpdateData={handleEditProfile}
            editable
          />
          {isAdmin && (
            <>
              <CoachAvailability
                isAvailable={availability}
                handleChange={changeCoachUser}
              />
              <Box sx={{ mb: 3 }}>
                <SelectMultipleGroups
                  initialSelectedGroups={data?.me.groups || []}
                  selectedGroups={selectedGroups}
                  setSelectedGroups={setSelectedGroups}
                  onCloseSelector={(groupIdsToAdd, groupIdsToRemove) => {
                    if (data?.me.id) {
                      addRemoveGroups(
                        data.me.id,
                        groupIdsToAdd,
                        groupIdsToRemove,
                        [GetProfileDocument]
                      );
                    }
                  }}
                  onRemoveChips={(groupIdsToRemove) => {
                    if (data?.me.id) {
                      removeGroups(data.me.id, groupIdsToRemove, [
                        GetProfileDocument,
                      ]);
                    }
                  }}
                  showPlaceholderOnly
                  showChips
                  fullWidth
                />
              </Box>
            </>
          )}
          <DescriptionCard user={data?.me} />
        </Stack>
        <Box flex={2} marginLeft={3}>
          <Accordion title="Change password" sx={{ padding: 1 }}>
            <ProfileChangePassword email={data?.me.email} />
          </Accordion>
          <CardButton
            label="Log out"
            handleClick={handleSignOut}
            icon="log-out-outline"
          />
        </Box>
      </Box>
    </BasePage>
  );
};

export default Profile;
