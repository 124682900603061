import React, { useEffect, useRef, useState } from 'react';
import { grey } from '@mui/material/colors';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  useTheme,
  Paper,
  TableCell,
  TableRow,
  Switch,
  Stack,
  Card,
} from '@mui/material';
import { resetPassword } from '@aws-amplify/auth';
import {
  useSnackbar,
  formatDate,
  getAge,
  Table,
  HeadCell,
  SearchableTableHeader,
  useDialog,
  cancerTypes,
  convertMaskToE164,
  Loader,
} from '@fdha/web-ui-library';
import Icon from 'react-eva-icons';
import {
  ApolloQueryResult,
  FetchMoreQueryOptions,
  NetworkStatus,
} from '@apollo/client';
import { parseISO } from 'date-fns';
import {
  Exact,
  FilterByField,
  Maybe,
  PageInfo,
  PatientUser,
  SortByField,
  useGetCoachUserQuery,
  useGetPatientsByCoachQuery,
  GetPatientsByCoachQuery,
  GetCoachUserDocument,
  useDeactivateAccountMutation,
  useGetProfileQuery,
  useUpdateCoachUserMutation,
  GetProfileDocument,
  useGetCommunityUserQuery,
  UserType,
} from '@fdha/graphql-api-admin';

import {
  useFilterBy,
  useSortBy,
  useDebouncedValue,
  useTable,
  useAddRemoveGroups,
  useGetUserType,
} from '../../hooks';
import {
  Avatar,
  BasePage,
  CoachAvailability,
  CoachCard,
  CommunityProfileCard,
  DescriptionCard,
  ManageAccountButton,
  SelectMultipleGroups,
} from '../../components/';

const useGetPatientsList = (
  coachId: string,
  rowsPerPage: number,
  sortBy: SortByField,
  filterBy: FilterByField
): {
  nodes?: PatientUser[];
  pageInfo?: PageInfo;
  totalNumberFound?: number;
  loading: boolean;
  networkStatus: NetworkStatus;
  fetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<
      Exact<{
        first?: Maybe<number> | undefined;
        after?: Maybe<string> | undefined;
      }>
    >
  ) => Promise<ApolloQueryResult<GetPatientsByCoachQuery>>;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            first?: Maybe<number> | undefined;
            after?: Maybe<string> | undefined;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetPatientsByCoachQuery>>;
} => {
  const result = useGetPatientsByCoachQuery({
    variables: {
      coachId: coachId,
      first: rowsPerPage,
      filterBy: {
        filterBy: [filterBy],
      },
      sortBy: {
        sortBy: [sortBy],
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const nodes = result.data?.patientsByCoach?.edges.map(
    (edge) => edge.node
  ) as PatientUser[];
  const pageInfo = result.data?.patientsByCoach?.pageInfo;
  const totalNumberFound = result.data?.patientsByCoach?.totalNumberFound;

  return {
    nodes,
    pageInfo,
    totalNumberFound,
    loading: result.loading,
    networkStatus: result.networkStatus,
    fetchMore: result.fetchMore,
    refetch: result.refetch,
  };
};

const getHeadCells = () => {
  const headCells: HeadCell<PatientUser>[] = [
    { id: 'name', label: 'Patient name', sortable: true, searchable: true },
    {
      id: 'subject_id',
      label: 'Subject ID',
      sortable: true,
      searchable: true,
    },
    {
      id: 'created_at',
      label: 'Joined on',
      sortable: true,
      searchable: false,
    },
    { id: 'birthdate', label: 'Age', sortable: true, searchable: false },
    {
      id: 'cancer_type',
      label: 'Cancer type',
      sortable: true,
      searchable: true,
    },
  ];

  return headCells;
};

interface StateProps {
  backRoute: string;
}

const Coach = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { isAdmin } = useGetUserType();
  const location = useLocation();
  const params = useParams();

  const state = location.state as StateProps;
  const coachId = params.coachId || '';

  const backRouteRef = useRef(state?.backRoute ?? '/settings/coaches');

  const { data } = useGetCoachUserQuery({
    variables: { id: coachId },
    fetchPolicy: 'cache-and-network',
  });
  const { data: meData } = useGetProfileQuery();
  const { page, setPage, rowsPerPage, changeRowsPerPage } = useTable({
    key: `coachPatients_${coachId}`,
  });
  const [queryFilterBy, setQueryFilterBy] = useFilterBy<PatientUser>(
    'name',
    ''
  );
  const [sortBy, setSortBy] = useSortBy<PatientUser>('name', 'asc');
  const queryFilterByDebounced = useDebouncedValue(queryFilterBy);
  const [deactivateAccount] = useDeactivateAccountMutation({
    variables: { id: coachId },
    refetchQueries: [GetCoachUserDocument],
  });
  const { addRemoveGroups, removeGroups } = useAddRemoveGroups();
  const { openDialog, closeDialog } = useDialog();

  const [updateCoachUser] = useUpdateCoachUserMutation();

  const {
    nodes,
    pageInfo,
    totalNumberFound,
    fetchMore,
    loading,
    networkStatus,
  } = useGetPatientsList(coachId, rowsPerPage, sortBy, queryFilterByDebounced);
  const { data: communityData } = useGetCommunityUserQuery({
    variables: { id: coachId },
  });

  const communityUser = communityData?.getCommunityUser;

  const isCurrentCoach = coachId === meData?.me.id;
  const isCsr = data?.coachUser?.type === UserType.CustomerService;

  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

  const [coachInfo, setCoachInfo] = useState({
    new_coachings_available: data?.coachUser?.new_coachings_available,
    is_admin: data?.coachUser?.is_admin,
    coachData: {
      name: data?.coachUser?.name,
      email: data?.coachUser?.email,
      created_at: data?.coachUser?.created_at,
      phone_number: data?.coachUser?.phone_number,
      picture: data?.coachUser?.picture,
    },
  });

  useEffect(() => {
    setSelectedGroups(data?.coachUser?.groups || []);

    setCoachInfo({
      new_coachings_available: data?.coachUser?.new_coachings_available,
      is_admin: data?.coachUser?.is_admin,
      coachData: {
        name: data?.coachUser?.name,
        email: data?.coachUser?.email,
        created_at: data?.coachUser?.created_at,
        phone_number: data?.coachUser?.phone_number,
        picture: data?.coachUser?.picture,
      },
    });
  }, [data]);

  useEffect(() => {
    if (
      networkStatus === NetworkStatus.refetch ||
      networkStatus === NetworkStatus.setVariables
    ) {
      setPage(0);
    }
  }, [networkStatus, setPage]);

  const onPageChange = (page: number, shouldLoadMore: boolean) => {
    if (pageInfo?.hasNextPage && shouldLoadMore) {
      fetchMore({
        variables: {
          first: rowsPerPage,
          after: pageInfo?.endCursor,
        },
      });
    }
    setPage(page);
  };

  const headCells = getHeadCells();

  if (loading) {
    return <Loader />;
  }

  const renderRow = (row: PatientUser) => {
    return (
      <TableRow hover key={row.id} data-testid="TABLE_ROW">
        <TableCell data-testid="PATIENT_NAME_CELL">
          <Avatar type="imageText" name={row.name} picture={row.picture} />
        </TableCell>
        <TableCell data-testid="SUBJECT_ID_CELL">{row.subject_id}</TableCell>
        <TableCell data-testid="JOINED_ON_CELL">
          {row.created_at && formatDate(parseISO(row.created_at))}
        </TableCell>
        <TableCell data-testid="AGE_CELL">{getAge(row.birthdate)}</TableCell>
        <TableCell data-testid="CANCER_TYPE_CELL">
          {
            cancerTypes.find(
              (cancerType) => cancerType.value === row.cancer_type
            )?.label
          }
        </TableCell>
        <TableCell padding="checkbox">
          <Link
            to={`/patient/${row.id}`}
            state={{ backRoute: `/coach/${coachId}` }}
            data-testid="PATIENT_PROFILE_BUTTON"
          >
            <Icon
              name="arrow-ios-forward-outline"
              fill={grey[600]}
              size="large"
            />
          </Link>
        </TableCell>
      </TableRow>
    );
  };

  const handleResetPassword = () => {
    try {
      resetPassword({ username: data?.coachUser?.email || '' });
      snackbar.showSnackbar({
        message: 'Reset password link sent to this user',
        severity: 'info',
      });
    } catch (e: any) {
      snackbar.showSnackbar({
        message: e.message,
        severity: 'warning',
      });
    }
  };

  const handleDeactivateAccClick = () => {
    openDialog({
      title: 'Are you sure you want to deactivate this account?',
      content: `${data?.coachUser?.name}’s account will no longer exist. This action can’t be undone.`,
      confirmButtonLabel: 'Deactivate',
      cancelButtonLabel: 'Cancel',
      handleConfirm: async () => {
        try {
          await deactivateAccount();

          snackbar.showSnackbar({
            message: 'User deactivated with success',
            severity: 'success',
          });

          navigate(backRouteRef.current, { replace: true });
        } catch (e) {
          snackbar.showSnackbar({
            message: 'Failed to deactivate account',
            severity: 'error',
          });
        } finally {
          closeDialog();
        }
      },
    });
  };

  const handleEditProfile = async (profile: {
    name?: string;
    email?: string;
    phone_number?: string;
  }) => {
    const formattedPhoneNumber = convertMaskToE164(profile.phone_number);
    setCoachInfo({
      ...coachInfo,
      coachData: {
        ...coachInfo.coachData,
        name: profile.name,
        email: profile.email,
        phone_number: formattedPhoneNumber,
      },
    });
    await updateCoachUser({
      variables: {
        id: coachId,
        userProps: {
          email: profile.email,
          phone_number: formattedPhoneNumber,
          name: profile.name,
          description: data?.coachUser?.description,
        },
      },
      refetchQueries: [GetCoachUserDocument],
    });
  };

  const canDeactivateAccount = () => {
    return meData?.me.is_admin && !data?.coachUser?.is_admin;
  };

  const changeCoachUser = async (
    prop: string,
    newValue: boolean,
    errorMessage: string
  ) => {
    const refetchQueries = isCurrentCoach
      ? [GetCoachUserDocument, GetProfileDocument]
      : [GetCoachUserDocument];
    try {
      setCoachInfo({
        ...coachInfo,
        [prop]: newValue,
      });
      await updateCoachUser({
        variables: {
          id: coachId,
          userProps: {
            [prop]: newValue,
          },
        },
        refetchQueries: refetchQueries,
      });
    } catch (e) {
      setCoachInfo({
        ...coachInfo,
        [prop]: !newValue,
      });
      snackbar.showSnackbar({
        message: `Failed to update user ${errorMessage}`,
        severity: 'error',
      });
    }
  };

  return (
    <BasePage data-testid="COACH_CONTAINER">
      <BasePage.BackButton to={backRouteRef.current} />
      {data?.coachUser ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          flex="1 0 auto"
          flexWrap="nowrap"
        >
          <Stack flex={1} spacing={2}>
            <CoachCard
              user={coachInfo.coachData}
              handleUpdateData={handleEditProfile}
              editable={meData?.me.is_admin}
            />
            {isAdmin && (
              <>
                {!isCurrentCoach && !isCsr && (
                  <Card
                    data-testid="IS_ADMIN_TOGGLE"
                    sx={{
                      paddingY: 1,
                      paddingX: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="body1"
                      color={theme.palette.secondary.contrastText}
                    >
                      Is Admin?
                    </Typography>
                    <Switch
                      checked={coachInfo.is_admin}
                      color="secondary"
                      onChange={(_, checked) =>
                        changeCoachUser('is_admin', checked, 'type')
                      }
                      data-testid={
                        coachInfo.is_admin
                          ? 'CHECKED_IS_ADMIN_TOGGLE'
                          : 'NOT_CHECKED_IS_ADMIN_TOGGLE'
                      }
                    />
                  </Card>
                )}
                {!isCsr && (
                  <CoachAvailability
                    isAvailable={coachInfo.new_coachings_available}
                    handleChange={changeCoachUser}
                  />
                )}
                <Box sx={{ mb: 3 }}>
                  <SelectMultipleGroups
                    initialSelectedGroups={data.coachUser.groups}
                    selectedGroups={selectedGroups}
                    setSelectedGroups={setSelectedGroups}
                    onCloseSelector={(groupIdsToAdd, groupIdsToRemove) =>
                      addRemoveGroups(
                        coachId,
                        groupIdsToAdd,
                        groupIdsToRemove,
                        [GetCoachUserDocument]
                      )
                    }
                    onRemoveChips={(groupIdsToRemove) =>
                      removeGroups(coachId, groupIdsToRemove, [
                        GetCoachUserDocument,
                      ])
                    }
                    showPlaceholderOnly
                    showChips
                    fullWidth
                  />
                </Box>
              </>
            )}
            <DescriptionCard user={data?.coachUser} editable={false} />
            {communityUser && !isCurrentCoach && (
              <CommunityProfileCard profile={communityUser} />
            )}
            <Typography variant="h6" sx={{ mb: 2, mt: 3 }}>
              Manage account
            </Typography>
            <ManageAccountButton
              data-testid="COACH_RESET_PASSWORD"
              onClick={handleResetPassword}
            >
              <Typography
                variant="h6"
                color={theme.palette.secondary.contrastText}
              >
                Reset password
              </Typography>
            </ManageAccountButton>
            {canDeactivateAccount() && (
              <ManageAccountButton
                onClick={handleDeactivateAccClick}
                data-testid="DEACTIVATE_ACCOUNT_BUTTON"
                color="error"
                sx={{ mt: 2 }}
              >
                <Box display="flex" alignItems="center">
                  <Icon
                    name="alert-circle-outline"
                    fill={theme.palette.error.textDark}
                    size="large"
                  />
                  <Typography variant="h6" sx={{ ml: 1 }}>
                    Deactivate account
                  </Typography>
                </Box>
              </ManageAccountButton>
            )}
          </Stack>
          <Box flex={2} marginLeft={3}>
            <SearchableTableHeader<PatientUser>
              headCells={headCells}
              defaultSearchField="name"
              onSearchChange={setQueryFilterBy}
            />
            <Paper data-testid="PATIENTS_TABLE">
              {nodes && (
                <Table<PatientUser>
                  actions="right"
                  initialOrderBy="name"
                  headCells={headCells}
                  page={page}
                  onPageChange={onPageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={changeRowsPerPage}
                  renderRow={renderRow}
                  rows={nodes}
                  isLoading={loading}
                  totalRowCount={totalNumberFound}
                  withPagination
                  onSortChange={setSortBy}
                />
              )}
            </Paper>
          </Box>
        </Box>
      ) : (
        <Typography>{`No coach found with ID ${coachId}`}</Typography>
      )}
    </BasePage>
  );
};

export default Coach;
